import Image from "next/image";

export default function LandingCardInfo({
  title,
  url,
}: {
  title: string;
  url: string;
}) {
  return (
    <div className="flex items-center flex-col group  hover:scale-105">
      <p className="py-1 text-center w-52 text-sm group-hover:text-sky-500 font-light duration-100 group-hover:-translate-y-1.5 leading-snug group-hover:font-semibold">
        {title}
      </p>
      <div className="flex items-center w-full ">
        <div className="aspect-square w-full  max-w-60 h-full max-h-60  rounded-lg overflow-hidden border relative cursor-pointer transition-transform duration-202">
          <Image alt="details" src={url} fill className="object-cover" />
        </div>
      </div>
    </div>
  );
}
